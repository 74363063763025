.fc .fc-day-today {
  background: rgba(var(--color-success-rgb), 0.05) !important;
  background-color: rgba(var(--color-success-rgb), 0.05) !important;
}
.fc tbody td,
.fc thead th,
.fc-scrollgrid {
  border-color: rgba(var(--theme-color-rgb), 0.13) !important;
}

.fc thead th {
  border: none !important;
}

.fc thead {
  background: transparent !important;
}

.fc th[role='presentation'] {
  border-bottom: 1px solid rgba(var(--theme-color-rgb), 0.13) !important;
  border-right: 1px solid rgba(var(--theme-color-rgb), 0.13) !important;
}

.fc .fc-toolbar .fc-button-group .fc-button {
  text-transform: capitalize;
}

.fc .fc-toolbar-chunk:first-child .fc-button-primary {
  background-color: transparent !important;
  border: none !important;
  color: var(--body-color) !important;
}

.fc
  .fc-toolbar-chunk:last-child
  .fc-button-primary:not(.fc-button-active):not(:hover) {
  background-color: transparent !important;
  border-color: var(--color-primary) !important;
  color: var(--color-primary) !important;
  padding: 0.55rem 1.5rem !important;
}

.fc .fc-toolbar-chunk:last-child .fc-button-primary {
  background-color: rgba(var(--color-primary-rgb), 0.2) !important;
  border-color: var(--color-primary) !important;
  color: var(--color-primary) !important;
  padding: 0.55rem 1.5rem !important;
}

.fc-button-primary:focus {
  box-shadow: none !important;
}

.fc tr:not(.fc-list-event) td:last-child,
.fc tr:not(.fc-list-event) td:first-child {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.fc-timegrid-axis,
.fc-scrollgrid-section.fc-scrollgrid-section-header th {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.bg-light-danger {
  &.fc-h-event,
  &.fc-v-event {
    border-color: rgba(var(--color-danger-rgb), 0.1) !important;
  }
}
.bg-light-primary {
  &.fc-h-event,
  &.fc-v-event {
    border-color: rgba(var(--color-primary-rgb), 0.12) !important;
  }
}
.bg-light-success {
  &.fc-h-event,
  &.fc-v-event {
    border-color: rgba(var(--color-success-rgb), 0.12) !important;
  }
}
.bg-light-info {
  &.fc-h-event,
  &.fc-v-event {
    border-color: rgba(var(--color-info-rgb), 0.12) !important;
  }
}
.bg-light-warning {
  &.fc-h-event,
  &.fc-v-event {
    border-color: rgba(var(--color-warning-rgb), 0.12) !important;
  }
}
.bg-light-secondary {
  &.fc-h-event,
  &.fc-v-event {
    border-color: rgba(var(--color-secondary-rgb), 0.12) !important;
  }
}

.fc-h-event .fc-event-main {
  color: currentColor !important;
}

.fc-day-past:not(.fc-day-other) .fc-daygrid-day-number,
.fc-day-future:not(.fc-day-other) .fc-daygrid-day-number {
  color: rgba(var(--theme-color-rgb), 0.35) !important;
  font-weight: bold;
}

.fc-day-today .fc-daygrid-day-number {
  color: rgba(var(--theme-color-rgb), 0.7) !important;
  font-weight: bold;
}

.fc .fc-list-event-dot {
  border: 5px solid currentColor !important;
}


.fc .fc-list-sticky .fc-list-day > * {
  background-color: transparent !important;
}

.fc-theme-standard td, .fc-theme-standard th, .fc-theme-standard .fc-list {
  border-color: rgba(var(--theme-color-rgb), 0.05) !important;
}



.fc-list-event.fc-event td:nth-child(odd) {
  color: var(--body-color) !important;
}

body.dark-theme  {
  .fc-col-header-cell-cushion {
    color: #c1c1c1 !important;
  }
  .fc .fc-list .fc-list-day-cushion {
    a {
      color: #c1c1c1 !important;
    }
  }
  .fc .fc-list .fc-list-day-cushion {
    background: rgba(0,0,0, 0.1) !important;
  }
  .fc-day-other {
    background: rgba(0,0,0,0.1) !important;
    .fc-daygrid-day-number  {
      color: rgba(var(--theme-color-rgb), 1) !important;
    }
  }
  .fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link {
    box-shadow: 0px 0px 3px 0px #00000094 !important;
  }
}
body:not(.dark-theme) {
  .fc .fc-list .fc-list-day-cushion {
    background: rgba(0,0,0, 0.05) !important;
  }
  .fc .fc-list .fc-list-day-cushion {
    a {
      color: #6e6b7b !important;
    }
  }
  .fc-day-other {
    background: rgba(0,0,0,0.05) !important;
    .fc-daygrid-day-number  {
      color: rgba(var(--theme-color-rgb), 1) !important;
    }
  }
  .fc-col-header-cell-cushion {
    color: #6e6b7b !important;
  }
}

.fc-v-event .fc-event-main {
  color: currentColor !important;
}

.fc-daygrid-event,
.fc-timegrid-event {
  font-weight: bold !important;
}

.fc-event {
  padding: 0.25rem 0.5rem;
}

.fc .fc-timegrid .fc-timegrid-slots .fc-timegrid-slot {
  height: 3rem;
}

.fc-list-event:not(:hover) {
  background-color: transparent !important;
}

.fc-list-event.bg-light-primary:hover td {
  background-color: rgba(var(--color-primary-rgb), 0.12) !important;
}
.fc-list-event.bg-light-danger:hover td {
  background: rgba(var(--color-danger-rgb), 0.12) !important;
}
.fc-list-event.bg-light-success:hover td {
  background-color: rgba(var(--color-success-rgb), 0.12);
}
.fc-list-event.bg-light-info:hover td {
  background-color: rgba(var(--color-info-rgb), 0.12);
}
.fc-list-event.bg-light-warning:hover td {
  background-color: rgba(var(--color-warning-rgb), 0.12);
}

.fc-list-event.bg-light-secondary:hover td {
  background-color: rgba(var(--color-secondary-rgb), 0.12);
}
