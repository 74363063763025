.timeline {
  padding: 0;
  margin-bottom: 0;
  margin-left: 1rem;
  list-style: none;
}
.timeline .timeline-item {
  position: relative;
  padding-left: 2.5rem;
  border-left: 1px solid #ebe9f1;
}
.timeline .timeline-item:not(:last-child) {
  padding-bottom: 1.8rem;
}
.timeline .timeline-item .timeline-point {
  position: absolute;
  left: -0.85rem;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.75rem;
  width: 1.75rem;
  text-align: center;
  border-radius: 50%;
  border: 1px solid var(--color-primary);
  background-color: var(--body-bg);
}
.timeline .timeline-item .timeline-point.timeline-point-indicator {
  left: -0.412rem;
  top: 0.07rem;
  height: 12px;
  width: 12px;
  border: 0;
  background-color: var(--color-primary);
}
.timeline .timeline-item .timeline-point.timeline-point-indicator:before {
  content: '';
  background: rgba(var(--color-primary-rgb), 0.12);
  height: 20px;
  width: 20px;
  display: block;
  position: absolute;
  top: -0.285rem;
  left: -0.285rem;
  border-radius: 50%;
}
.timeline .timeline-item .timeline-point i,
.timeline .timeline-item .timeline-point svg {
  color: var(--color-primary);
  font-size: 0.85rem;
  vertical-align: baseline;
}
.timeline .timeline-item .timeline-event {
  position: relative;
  width: 100%;
  min-height: 4rem;
}
.timeline .timeline-item .timeline-event .timeline-event-time {
  font-size: 0.85rem;
  color: #b9b9c3;
}
.timeline .timeline-item:last-of-type {
  border-left-color: transparent !important;
}
.timeline .timeline-item:last-of-type:after {
  content: '';
  position: absolute;
  left: -1px;
  bottom: 0;
  width: 1px;
  height: 100%;
  background: linear-gradient(#ebe9f1, transparent);
}
.timeline .timeline-point-dark {
  border-color: #4b4b4b !important;
}
.timeline .timeline-point-dark i,
.timeline .timeline-point-dark svg {
  stroke: #4b4b4b !important;
}
.timeline .timeline-point-dark.timeline-point-indicator {
  background-color: #4b4b4b !important;
}
.timeline .timeline-point-dark.timeline-point-indicator:before {
  background: rgba(75, 75, 75, 0.12) !important;
}

.timeline .timeline-point-primary {
  border-color: var(--color-primary) !important;
}
.timeline .timeline-point-primary i,
.timeline .timeline-point-primary svg {
  stroke: var(--color-primary) !important;
}
.timeline .timeline-point-primary.timeline-point-indicator {
  background-color: var(--color-primary) !important;
}
.timeline .timeline-point-primary.timeline-point-indicator:before {
  background: rgba(var(--color-primary-rgb), 0.12) !important;
}

.timeline .timeline-point-secondary {
  border-color: var(--color-secondary) !important;
}
.timeline .timeline-point-secondary i,
.timeline .timeline-point-secondary svg {
  stroke: var(--color-secondary) !important;
}
.timeline .timeline-point-secondary.timeline-point-indicator {
  background-color: var(--color-secondary) !important;
}
.timeline .timeline-point-secondary.timeline-point-indicator:before {
  background: rgba(var(--color-secondary-rgb), 0.12) !important;
}
.timeline .timeline-point-success {
  border-color: var(--color-success) !important;
}
.timeline .timeline-point-success i,
.timeline .timeline-point-success svg {
  stroke: var(--color-success) !important;
}
.timeline .timeline-point-success.timeline-point-indicator {
  background-color: var(--color-success) !important;
}
.timeline .timeline-point-success.timeline-point-indicator:before {
  background: rgba(var(--color-success-rgb), 0.12) !important;
}
.timeline .timeline-point-info {
  border-color: var(--color-info) !important;
}
.timeline .timeline-point-info i,
.timeline .timeline-point-info svg {
  stroke: var(--color-info) !important;
}
.timeline .timeline-point-info.timeline-point-indicator {
  background-color: var(--color-info) !important;
}
.timeline .timeline-point-info.timeline-point-indicator:before {
  background: rgba(var(--color-info-rgb), 0.12) !important;
}
.timeline .timeline-point-warning {
  border-color: var(--color-warning) !important;
}
.timeline .timeline-point-warning i,
.timeline .timeline-point-warning svg {
  stroke: var(--color-warning) !important;
}
.timeline .timeline-point-warning.timeline-point-indicator {
  background-color: var(--color-warning) !important;
}
.timeline .timeline-point-warning.timeline-point-indicator:before {
  background: rgba(var(--color-warning-rgb), 0.12) !important;
}
.timeline .timeline-point-danger {
  border-color: var(--color-danger) !important;
}
.timeline .timeline-point-danger i,
.timeline .timeline-point-danger svg {
  stroke: var(--color-danger) !important;
}
.timeline .timeline-point-danger.timeline-point-indicator {
  background-color: var(--color-danger) !important;
}
.timeline .timeline-point-danger.timeline-point-indicator:before {
  background: rgba(var(--color-danger-rgb), 0.12) !important;
}
