@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700');
@import './animation.scss';
@import './timeline.scss';
@import './badge.scss';
@import './calendar.scss';
@import './flatpicker.scss';
@import './avatar.scss';

:root {
  --color-primary-rgb: 192, 187, 135;
  --color-secondary-rgb: 0, 188, 225;
  --color-danger-rgb: 234, 84, 85;
  --color-success-rgb: 40, 199, 111;
  --color-info-rgb: 0, 207, 232;
  --color-warning-rgb: 255, 159, 67;
  --dark-blue-rgb: 0, 43, 73;

  --dark-blue: #002B49;
  --color-text: #002138;

  --color-primary: #C0BB87;

  --color-secondary: #00BCE1;
  --color-secondary-lighten: #f2f2f2;

  --color-success: #19761E;
  --color-success-lighten: #e5f8ed;

  --color-info: #00cfe8;
  --color-info-lighten: #e0f9fc;

  --color-danger: #DB324D;
  --color-danger-lighten: #fceaea;

  --color-warning: #F0CF58;
  --color-warning-lighten: #fff3e8;

  --color-gray0: #f2f3f8;
  --color-gray1: #ededed;
  --color-gray2: #dae1e7;
  --color-gray3: #babfc7;
  --color-gray4: #b8b8b8;
  --color-gray5: #a3a3a3;
  --color-gray6: #8f8f8f;
  --color-gray7: #6d696a;
  --color-gray8: #535051;
  --color-gray9: #2a2829;
  --border-radius: 0.4rem;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background-color: rgba(var(--theme-color-rgb), 0.05);
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(var(--theme-color-rgb), 0.1);
}

* {
  box-sizing: border-box;
  outline: none !important;
}

// hr.bold {
//   border-top: 3px solid rgba(0, 0, 0, 0.05) !important;
// }
// hr:not(.bold) {
//   border-top: 2px solid rgba(0, 0, 0, 0.05) !important;
// }

html {
  font-size: 14px;
  letter-spacing: 0.01rem;
}

body {
  margin: 0;
  padding-right: 0 !important;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
  background-color: var(--color-gray0);
  font-size: 1rem;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// a {
//   color: #fff;
//   display: inline-block;
//   text-decoration: none;
//   font-weight: 400;
// }

// h2 {
//   text-align: center;
//   font-size: 16px;
//   font-weight: 600;
//   text-transform: uppercase;
//   display: inline-block;
//   margin: 40px 8px 10px 8px;
//   color: #cccccc;
// }

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 80px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: var(--color-info);
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;

  /* TABS */

  h2.inactive {
    color: #cccccc;
  }

  h2.active {
    color: #fff;
    border-bottom: 2px solid var(--color-primary);
  }
  input[type='button'],
  input[type='submit'],
  input[type='reset'] {
    background-color: #538dff;
    border: none;
    color: white;
    padding: 15px 80px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
    box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
    margin: 5px 20px 40px 20px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  input[type='button']:hover,
  input[type='submit']:hover,
  input[type='reset']:hover {
    background-color: #fff;
    color: var(--color-info);
  }

  input[type='button']:active,
  input[type='submit']:active,
  input[type='reset']:active {
    -moz-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
  }

  input[type='text'] {
    background-color: #f6f6f6;
    border: none;
    color: #0d0d0d;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 5px;
    width: 85%;
    border: 2px solid #f6f6f6;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
  }

  input[type='text']:focus {
    background-color: #fff;
    border-bottom: 2px solid #5fbae9;
  }

  input[type='text']:placeholder {
    color: #fff;
  }

  .underlineHover:after {
    display: block;
    left: 0;
    bottom: -10px;
    width: 0;
    height: 2px;
    background-color: var(--color-primary);
    content: '';
    transition: width 0.2s;
  }

  .underlineHover:hover {
    color: #fff;
  }

  .underlineHover:hover:after {
    width: 100%;
  }
}

body:not(.dark-theme) {
  --theme-color-rgb: 0, 0, 0;
  --body-color: var(--color-text);
  --body-bg: #fff;
  --txt-muted: #737373;

  .form-control {
    &::-webkit-input-placeholder {
      color: #c1c1cb !important;
    }
    &:-moz-placeholder {
      color: #c1c1cb !important;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #c1c1cb !important;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #c1c1cb !important;
    }
    &::-ms-input-placeholder {
      color: #c1c1cb !important;
    }

    &::placeholder {
      transition: all 0.2s ease;
      color: #b9b9c3 !important;
    }
  }

  .card {
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  }

  color: var(--body-color) !important;
  table {
    color: #002138 !important;
  }
  .pro-sidebar {
    color: #646885;
    font-weight: 600;
  }
  label {
    color: #002B49;
  }
  .pro-sidebar .pro-menu .pro-menu-item:not(.active) > .pro-inner-item:hover,
  .pro-sidebar .pro-menu .pro-menu-item:not(.active) > .pro-inner-item:focus {
    color: #646885 !important;
  }
  .pro-sidebar .pro-menu .pro-menu-item:not(.active) a {
    color: #646885;
  }
  .bg-gray {
    background-color: var(--color-gray0);
  }
  .bg-gray1 {
    background-color: var(--color-gray1);
  }
  .navbar-container {
    background: #fff;
  }
  thead {
    background-color: #dce3eb;
  }
  .txt-muted {
    color: #627E98 !important;
  }
  .pro-sidebar > .pro-sidebar-inner {
    background: var(--dark-blue);
  }
  .dashboard .navbar-container-bg {
    background: linear-gradient(
      180deg,
      rgba(248, 248, 248, 0.95) 44%,
      rgba(248, 248, 248, 0.46) 73%,
      rgba(255, 255, 255, 0)
    );
  }
  .btn-close {
    box-shadow: 0 5px 20px 0 rgba(34, 41, 47, 0.1);
    background: #fff
      url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='darkslategray'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E")
      50%/0.75rem auto no-repeat !important;
  }
}
body.dark-theme {
  --theme-color-rgb: 255, 255, 255;
  --body-color: #d0d2d6;
  --body-bg: #26252a;
  --txt-muted: #999999;
  color: var(--body-color) !important;

  code {
    color: #FF5A9C;
  }

  .bg-gray,
  .bg-gray1 {
    background-color: #1e1d22;
  }

  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  .card {
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.28);
  }

  .form-control {
    &::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.2) !important;
    }
    &:-moz-placeholder {
      color: rgba(255, 255, 255, 0.2) !important;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: rgba(255, 255, 255, 0.2) !important;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.2) !important;
    }
    &::-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.2) !important;
    }

    &::placeholder {
      transition: all 0.2s ease;
      color: rgba(255, 255, 255, 0.2) !important;
    }
  }

  color: var(--body-color) !important;
  .pro-sidebar {
    color: #b4b7bd;
  }
  .txt-muted {
    color: #999999 !important;
  }
  table {
    color: #b4b7bd !important;
  }
  label,
  .input-group-text,
  .custom.input-group select {
    color: #d0d2d6;
  }
  .custom.input-group select option {
    background-color: var(--body-bg);
  }
  input {
    color: #b4b7bd;
  }
  .pro-sidebar .pro-menu .pro-menu-item:not(.active) a {
    color: #b4b7bd;
  }
  .modal-content {
    background-color: #1e1d22;
  }
  .navbar-container,
  .card {
    background: var(--body-bg) !important;
  }

  thead {
    background-color: #1c1b20;
  }

  .pro-sidebar > .pro-sidebar-inner {
    background: var(--body-bg);
  }

  .dashboard .navbar-container-bg {
    background: linear-gradient(
      180deg,
      rgba(30, 29, 34, 0.95) 44%,
      rgba(30, 29, 34, 0.46) 73%,
      rgba(30, 29, 34, 0)
    );
  }
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  #swal2-html-container {
    color: #d0d2d6;
  }

  .btn-close {
    box-shadow: 0 5px 20px 0 rgba(130, 130, 130, 0.1);

    background: var(--body-bg)
      url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='gray'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E")
      50%/0.75rem auto no-repeat !important;
  }
}

.offcanvas {
  background-color: var(--body-bg) !important;

  .btn-close {
    transform: translate(3px, -5px) !important;
  }

  .offcanvas-header {
    background: rgba(var(--theme-color-rgb), 0.025);
  }
}

.bg-white-theme {
  background-color: var(--body-bg);
}

#formFooter {
  background-color: var(--color-info);
  border-top: 1px solid #fff;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

#icon {
  margin-top: 15px;
  width: 60%;
}

.justify-center {
  display: flex;
  justify-content: center;
}
.justify-between {
  display: flex;
  justify-content: space-between !important;
}
.justify-end {
  display: flex;
  justify-content: flex-end;
}
.justify-around {
  display: flex;
  justify-content: space-around;
}
.justify-start {
  display: flex;
  justify-content: flex-start !important;
}
.text-end {
  text-align: end;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify !important;
}
.font-medium {
  font-weight: 500 !important;
}
.bold {
  font-weight: bold !important;
}
.bolder {
  font-weight: bolder !important;
}

footer {
  position: fixed;
  left: 0;
  bottom: 5px;
  width: 100%;
  color: white;
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.h-100 {
  height: 100%;
}

.vh-100 {
  height: 100vh;
}
.vwm-100 {
  max-width: 100vw;
}

.w-6 {
  width: 6%;
}
.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-100 {
  width: 100%;
}

.p-5 {
  padding: 4rem !important;
}

.pl-5,
.px-5 {
  padding-left: 4rem !important;
}

.pr-5,
.px-5 {
  padding-right: 4rem !important;
}

.pl-1 {
  padding-left: 0.5rem !important;
}
.pl-2 {
  padding-left: 1rem !important;
}
.pl-3 {
  padding-left: 2rem !important;
}

.pr-1 {
  padding-right: 0.5rem !important;
}
.pr-2 {
  padding-right: 1rem !important;
}
.pr-3 {
  padding-right: 2rem !important;
}

.ml-3 {
  margin-left: 2rem !important;
}

.mr-3 {
  margin-right: 2rem !important;
}

.mr-10 {
  margin-right: 10rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

// .mt-2, .my-2 {
//   margin-top: 1.5rem!important;
// }
// .mb-2, .my-2 {
//   margin-bottom: 1.5rem!important;
// }

.d-none {
  display: none !important;
}

.img-fluid,
.img-thumbnail {
  max-width: 100%;
  height: auto;
}

textarea.form-control {
  min-height: 7rem !important;
}

.form-control:not(textarea) {
  height: 2.714rem !important;
}

.form-control {
  display: block !important;
  width: 100% !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.45 !important;
  color: currentColor !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  padding: 0.438rem 1rem;
  background-color: transparent !important;
  background-clip: padding-box !important;
  border: 1px solid !important;
  border-color: rgba(var(--theme-color-rgb), 0.1) !important;
  border-radius: var(--border-radius) !important;

  &.disabled,
  &:disabled {
    background-color: rgba(var(--theme-color-rgb), 0.03) !important;
  }

  &:focus {
    box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1) !important;
    border-color: var(--dark-blue) !important;
  }

  &:hover:not(:focus) {
    border-color: rgba(var(--theme-color-rgb), 0.15) !important;
  }

  &.error {
    border-color: var(--color-danger) !important;
  }
}

.form-control-group {
  width: 100%;
  height: 2.6rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: var(--body-color) !important;
  padding: 0.438rem 1rem;
  background-color: transparent;
  background-clip: padding-box;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.input-group {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid;
  border-color: rgba(var(--theme-color-rgb), 0.1) !important;
  border-radius: var(--border-radius) !important;
  &:hover {
    border-color: rgba(var(--theme-color-rgb), 0.15) !important;
  }
}
.input-group.error {
  border-color: var(--color-danger);
}

.input-group:focus-within {
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1) !important;
  border-color: var(--dark-blue) !important;
}

.input-group .input-group-prepend {
  margin-left: 10px;
}
.input-group .input-group-append {
  margin-right: 10px;
}

@media (min-width: 992px) {
  .d-lg-flex {
    display: flex !important;
  }
  .list-item {
    min-width: 260px;
    width: calc((100% - 10px) / 5 - 10px);
    max-height: 600px;
    min-height: 460px;
    margin-top: 20px;
  }
  .chart-card {
    .chart-card-body {
      height: calc((100vh - 110px) / 2 - 43px - 1rem);
      min-height: 400px;
    }
  }
  .chart-card-xs {
    .chart-card-body {
      height: calc((100vh - 110px) / 4 - 43px - 1rem);
      min-height: 200px;
    }
  }
  .chart-card-sm {
    .chart-card-body {
      height: calc((100vh - 110px) / 3 - 43px - 1rem);
      min-height: 240px;
    }
  }
  .chart-card-lg {
    .chart-card-body {
      height: calc((100vh - 110px) / 1.5 - 30px - 1rem);
    }
  }
}
@media (max-width: 991px) {
  .list-item {
    min-width: 85vw;
    max-width: 85vw;
    height: calc(100vh - 100px);
  }
  .chart-card {
    .chart-card-body {
      height: calc((100vh - 272px) / 1.2 - 0.25rem);
    }
  }
  .chart-card-xs {
    .chart-card-body {
      height: calc((100vh - 272px) / 1.4 - 0.25rem);
    }
  }
  .chart-card-sm {
    .chart-card-body {
      height: calc((100vh - 272px) / 1.6 - 0.25rem);
    }
  }
  .chart-card-lg {
    .chart-card-body {
      height: calc((100vh - 272px) / 1.1 - 0.25rem);
    }
  }

  .sm-height-400 {
    height: 400px;
  }
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  border-color: #646885 !important;
}

.pro-sidebar img {
  height: 50px;
  transition: all 0.3s;
}
.pro-sidebar.collapsed img {
  height: 35px;
}
@media (max-width: 768px) {
  .pro-sidebar.collapsed:not(.toggled) {
    left: -270px !important;
  }
  .pro-sidebar.collapsed {
    width: 270px !important;
    min-width: 270px !important;
  }

  .md-none {
    display: none !important;
  }
  .dashboard .navbar-container {
    width: calc(100%) !important;
    right: 0px !important;
    margin-top: 0 !important;
    border-radius: 0 !important;
    position: relative !important;
  }
  .dashboard main {
    margin: 1.1rem 1.1rem 0px !important;
  }
  .d-sm-grid {
    display: grid;
  }
  table.rwd-table {
    border: 0;

    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tr {
      display: block;
      margin-bottom: 1.025em;
      box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.12);
      border-radius: 4px;

      &:hover {
        box-shadow: 0px 5px 11px #0000001f !important;
      }
    }

    td {
      border-top: none !important;
      border-bottom: 1px solid #eee;
      display: block;
      font-size: 0.9em;
      text-align: right !important;
      font-weight: 500;
      min-height: 37px;
      padding-top: 17px;
      padding-left: 1rem !important;
      padding-right: 1rem !important;

      .avatar-group {
        justify-content: flex-end;
      }

      &::before {
        /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
        content: attr(data-label);
        float: left !important;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.8em;
        margin-top: -15px;
        margin-right: 5px;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    .justify-between-sm {
      display: flex;
      justify-content: space-between;
    }

    .acao {
      border-left: none !important;
    }

    [class*='w-'] {
      width: 100% !important;
    }
  }
  .rwd-table .btn-group {
    display: flex !important;
  }
  .rwd-table tr td {
    word-break: break-word;
  }
  .justify-content-sm-between {
    justify-content: space-between;
  }
}
@media (min-width: 768px) and (max-width: 768px) {
  .dashboard .navbar-container {
    width: calc(100%) !important;
  }
  .d-lg-none {
    display: block !important;
  }
  .md-none {
    display: none !important;
  }
}
@media (min-width: 769px) {
  .float-md-right {
    float: right;
  }
  .d-lg-flex {
    display: flex !important;
  }
}

.brand-logo {
  filter: drop-shadow(0 0 6px black);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: #5e5873;
}

label {
  font-size: 0.9rem;
  margin-bottom: 0.2857rem;
}

.form-group {
  margin-bottom: 1.25rem;
}

.ml-3 {
  margin-left: 0.5rem;
}

* {
  box-sizing: border-box;
}
.cbx-round span {
  border-radius: 50% !important;
}
.cbx {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s ease;
}
.cbx:not(:last-child) {
  margin-right: 6px;
}

.cbx span {
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.cbx span:first-child {
  position: relative;
  min-width: 18px;
  max-width: 18px;
  height: 18px;
  border-radius: 4px;
  transform: scale(1);
  border: 1px solid #cccfdb;
  transition: all 0.2s ease;
  box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
}
.cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}

.cbx span:last-child {
  padding-left: 8px;
  line-height: 18px;
  font-size: 1.1rem;
}
.cbx:hover span:first-child {
  border-color: var(--color-info);
}
.inp-cbx {
  position: absolute;
  visibility: hidden;
}
.inp-cbx:checked + .cbx span:first-child {
  background: var(--dark-blue);
  border-color: var(--dark-blue);
  box-shadow: 0 2px 4px 0 rgb(var(--dark-blue-rgb), 0.4);
  animation: wave 0.4s ease;
}
.inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  color: #223254;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inline-svg {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}

@media screen and (max-width: 640px) {
  .cbx {
    width: 100%;
    margin-bottom: 4px;
    display: inline-block;
  }
}

#root {
  width: 100%;
}

.btn-block {
  width: 100%;
}

.btn {
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.786rem 1.5rem;
  border-radius: var(--border-radius) !important;
  vertical-align: middle;
  user-select: none;
  font-weight: 500 !important;
  font-size: 1rem;
  line-height: 1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s,
    border 0s;
}

.btn-primary {
  color: #494433 !important;
  border-color: var(--color-primary) !important;
  background-color: var(--color-primary) !important;
}
.btn-blue {
  border-color: var(--dark-blue) !important;
  background-color: var(--dark-blue) !important;
  color: #fff !important;
}
.btn-blue:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 6px 20px -10px var(--color-primary);
  background-color: #002138 !important;
}
.btn-primary:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 6px 20px -10px var(--color-primary);
}
.btn-secondary {
  border-color: var(--color-secondary) !important;
  background-color: var(--color-secondary) !important;
}
.btn-secondary:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 6px 20px -10px var(--color-secondary);
}
.btn-success {
  border-color: var(--color-success) !important;
  background-color: var(--color-success) !important;
}
.btn-success:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 6px 20px -10px var(--color-success);
}
.btn-danger {
  border-color: var(--color-danger) !important;
  background-color: var(--color-danger) !important;
}
.btn-danger:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 6px 20px -10px var(--color-danger);
}
.btn-info {
  border-color: var(--color-info) !important;
  background-color: var(--color-info) !important;
}
.btn-info:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 6px 20px -10px var(--color-info);
}
.btn-warning {
  border-color: var(--color-warning) !important;
  background-color: var(--color-warning) !important;
}
.btn-warning:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 6px 20px -10px var(--color-warning);
}
.btn-light-primary {
  background-color: rgba(var(--color-primary-rgb), 0.12) !important;
  color: var(--color-primary) !important;
}
.btn-light-blue {
  background-color: rgba(var(--dark-blue-rgb), 0.12) !important;
  color: var(--dark-blue) !important;
}
.btn-light-danger {
  background-color: rgba(var(--color-danger-rgb), 0.12) !important;
  color: var(--color-danger) !important;
}
.btn-light-info {
  background-color: rgba(var(--color-info-rgb), 0.12) !important;
  color: var(--color-info) !important;
}
.btn-light-warning {
  background-color: rgba(var(--color-warning-rgb), 0.12) !important;
  color: var(--color-warning) !important;
}
.btn-light-success {
  background-color: rgba(var(--color-success-rgb), 0.12) !important;
  color: var(--color-success) !important;
}
.btn-light-secondary {
  background-color: rgba(var(--color-secondary-rgb), 0.12) !important;
  color: var(--color-secondary) !important;
}

a {
  text-decoration: none !important;
}

small.error {
  color: #de3202;
}

.spinner {
  -webkit-animation: rotator 1.4s linear infinite;
  animation: rotator 1.4s linear infinite;
}

@-webkit-keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  -webkit-animation: dash 1.4s ease-in-out infinite;
  animation: dash 1.4s ease-in-out infinite;
}

.pro-sidebar {
  height: 100vh !important;
  // box-shadow: 0 0 15px 0 rgba(34, 41, 47, 0.05);
  box-shadow: none !important;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-menu-item.active
  .pro-inner-item:before {
  width: 10px !important;
  min-width: 10px !important;
  height: 10px !important;
  margin-right: 25px;
  border-color: var(--color-primary) !important;
}
.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-menu-item:hover
  .pro-inner-item:before {
  border-color: #fff !important;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-menu-item:not(.active)
  .pro-inner-item:before {
  width: 10px !important;
  min-width: 10px !important;
  height: 10px !important;
  margin-right: 25px;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover,
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
  color: #fff !important;
}

.pro-sidebar .pro-menu .pro-menu-item {
  padding: 0 10px;
  // border-radius: var(--border-radius);

  &.active {
    color: var(--color-primary) !important;
    // background: linear-gradient(
    //   118deg,
    //   var(--color-primary),
    //   rgba(var(--color-primary-rgb), 0.7)
    // );
    // box-shadow: 0 2px 10px 0.5px rgba(0, 0, 0, 0.3);
  }
}

.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper,
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  background-color: transparent !important;
}

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item
  > .popper-inner {
  padding-left: 10px !important;
  padding-right: 10px !important;

  .pro-menu-item:hover a{
    color: var(--color-text) !important;
  }
}

.pro-menu {
  padding: 10px 0px;
  margin-left: 10px;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.pro-menu-item.pro-sub-menu.has-active .pro-inner-item {
  background-color: rgba(var(--theme-color-rgb), 0.04);
  border-radius: var(--border-radius);
}

.card {
  border: none;
  margin-bottom: 2rem;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.428rem;
  border: none !important;
}

.dashboard {
  max-height: 100vh;
  min-height: 100vh;
  overflow: auto;

  main {
    margin: 90px 1.8rem 1.1rem 1.8rem;
  }
}

.navbar-container {
  padding: 1rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.05);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 12;
  transition: all 0.3s;
}

.dashboard .navbar-container-bg {
  position: fixed;
  right: 0px;
  margin-bottom: 20px;
  height: 40px;
  z-index: 11;
  width: calc(100%);
}

.pro-sidebar:not(.collapsed) {
  .xcoding-text {
    display: none;
  }
}

.xcoding {
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 24.5px;
}

.pro-sidebar.collapsed {
  .xcoding-text {
    display: flex;
  }

  .xcoding {
    display: none !important;
  }
}

.pro-sidebar-header button:hover  svg {
  color: #fff !important;
}

.pro-sidebar.collapsed ~ .dashboard .navbar-container {
  width: calc(100% - 80px);
}

.pro-sidebar:not(.collapsed) ~ .dashboard .navbar-container {
  width: calc(100% - 270px);
}

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu:hover
  > .pro-inner-list-item {
  margin-left: 0px !important;
  margin-top: 60px !important;
}

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item
  > .popper-inner {
  background-color: #fff !important;
  box-shadow: 20px 3px 24px 0 rgba(34, 41, 47, 0.1);
}

.nav-user {
  box-shadow: none !important;
  text-decoration: none !important;
}

.avatar img {
  box-shadow: 0 4px 8px 0 rgba(34, 41, 47, 0.12),
    0 2px 4px 0 rgba(34, 41, 47, 0.08);
  border-radius: 50%;
  object-fit: cover;
}

.p-0 {
  padding: 0 !important;
}

.nav-user {
  color: var(--body-color) !important;
  font-weight: 600;

  & ~ .dropdown-menu.show {
    margin-top: 8px;
    border: none;
    border-radius: 0.358rem;
    box-shadow: 0 4px 25px rgba(34, 41, 47, 0.1);
    color: var(--body-color) !important;
  }
}

.dropdown-item {
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  color: var(--body-color) !important;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: rgba(var(--dark-blue-rgb), 0.12) !important;
    color: var(--dark-blue) !important;
  }
}

.dropdown-divider {
  margin: 0.5rem 0;
  border-top: 1px solid rgba(34, 41, 47, 0.08);
}

.pro-sidebar {
  top: 0;
}

.min-100h {
  min-height: 100vh;
}

.pro-sidebar-header,
.pro-sidebar-footer {
  border-color: transparent !important;
}

thead {
  th,
  td {
    color: var(--body-color);
  }
}

thead tr,
thead th,
tr:last-child {
  border-color: transparent !important;
}

tr td:first-child,
tr th:first-child {
  padding-left: 20px;
}
tr td:last-child,
tr th:last-child {
  padding-right: 20px;
}

select.form-control {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23d8d6de' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-size: 22px 25px, 22px 25px;
  background-repeat: no-repeat;
  background-position-x: 99%;
  background-position-y: 6px;

  option {
    background-color: var(--body-bg);
  }
}

.pagination {
  li {
    width: 32px;
    height: 32px;
    display: flex;
    background-color: rgba(var(--dark-blue-rgb), 0.12);

    &.previous,
    &.previous a {
      border-radius: 4px 0 0 4px;
    }
    &.next,
    &.next a {
      border-radius: 0 4px 4px 0;
    }

    a {
      width: -webkit-fill-available;
      display: flex;
      padding: 2px;
      color: var(--dark-blue) !important;
      outline: none;
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
      font-weight: bold;

      &[aria-disabled='true'] {
        color: rgba(var(--theme-color-rgb), 0.16) !important;
      }
    }

    &.active a {
      color: #fff !important;
      background-color: var(--dark-blue);
      border-radius: 4px;
    }
  }
}

.pro-sidebar .pro-menu .pro-menu-item.active a {
  color: var(--color-primary) !important;
}
.pro-sidebar .pro-menu .pro-menu-item.active:hover a {
  color: #fff !important;
}
.pro-inner-item:hover * {
  color: #fff !important;
}

.pro-sidebar .pro-menu .pro-menu-item:hover svg {
  color: #fff !important;
}

.card {
  border-radius: var(--border-radius);
}

// .radio {
//   position: relative;
//   margin: 0 1rem 0 0;
//   cursor: pointer;
// }
// .radio:before {
//   -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
//   -moz-transition: -moz-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
//   transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
//   -webkit-transform: scale(0, 0);
//   -moz-transform: scale(0, 0);
//   -ms-transform: scale(0, 0);
//   -o-transform: scale(0, 0);
//   transform: scale(0, 0);
//   content: '';
//   position: absolute;
//   top: 0.6px;
//   left: 1.2px;
//   z-index: 1;
//   width: 0.85rem;
//   height: 0.85rem;
//   background: var(--color-primary);
//   border-radius: 50%;
// }
// .radio:checked:before {
//   -webkit-transform: scale(1, 1);
//   -moz-transform: scale(1, 1);
//   -ms-transform: scale(1, 1);
//   -o-transform: scale(1, 1);
//   transform: scale(1, 1);
// }
// .radio:after {
//   content: '';
//   position: absolute;
//   top: -1.5px;
//   left: -1px;
//   width: 1.15rem;
//   height: 1.15rem;
//   background: var(--body-bg);
//   border: 1.5px solid rgba(var(--theme-color-rgb), 0.3);
//   border-radius: 50%;
// }

.flex-wrap-inherit {
  flex-wrap: inherit !important;
}

.btn-link {
  box-shadow: none !important;
}
.pro-sidebar .pro-menu .pro-menu-item.active {
  color: var(--color-primary) !important;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 0px !important;
}

.li.pro-menu-item.pro-sub-menu.has-active.open:not(.active) {
  padding: 6px 0px 6px 4px !important;
}
// .pro-sub-menu .pro-menu-item.active {
//   padding: 12px 0px 12px 4px !important;
// }

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item {
  padding: 10px 13px !important;
  margin-top: 3px;
}

.pro-sidebar .pro-menu .pro-menu-item:not(.pro-sub-menu) > .pro-inner-item {
  padding: 5px 35px 5px 0px !important;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item {
  padding: 5px 35px 5px 10px !important;
}

li.pro-menu-item.pro-sub-menu {
  padding: 0 !important;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item {
  background-color: transparent !important;
}

.tab-content {
  margin-top: 10px;
}

.nav-tabs {
  border-bottom: 1px solid rgba(var(--theme-color-rgb), 0.2) !important;

  .nav-item {
    cursor: pointer;
    .nav-link {
      border-top-width: 2px !important;
      border-left-width: 0px !important;
      border-right-width: 0px !important;
      border-bottom-width: 2px !important;
      border-top-color: transparent !important;
      font-weight: 600;

      &.active {
        color: var(--dark-blue) !important;
        border-bottom-color: var(--dark-blue) !important;
      }

      &:hover {
        background-color: rgba(var(--dark-blue-rgb), 0.05);
        color: var(--dark-blue);
        &:not(.active) {
          border-color: rgba(var(--theme-color-rgb), 0.1);
        }
      }
      &:not(:hover) {
        background-color: transparent !important;
      }

      &:not(:hover):not(.active) {
        color: rgba(var(--theme-color-rgb), 0.6);
      }
    }
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  transition: box-shadow 0.5s;
  background-color: var(--color-primary) !important;
  box-shadow: 0 4px 18px -4px rgba(var(--color-primary-rgb), 0.65);
  &:hover {
    box-shadow: 0 4px 10px -4px rgba(var(--color-primary-rgb), 0.65);
  }
}

.modal .modal-header {
  background-color: rgba(var(--theme-color-rgb), 0.02);
  border-bottom: none;
}

.modal .modal-header,
.modal .modal-header {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0.7rem 0.9rem;
}

.modal-header,
.modal-header {
  border-top-left-radius: calc(0.458rem - 1px);
  border-top-right-radius: calc(0.458rem - 1px);
}

.modal .modal-header .btn-close:active,
.modal .modal-header .btn-close:focus,
.modal .modal-header .btn-close:hover {
  transform: translate(15px, -10px);
}

.modal-content {
  border: none !important;
  box-shadow: 0 5px 20px 0 rgba(34, 41, 47, 0.2);
}

.btn-close {
  padding: 0.65rem !important;
  border-radius: 0.357rem;
  opacity: 1 !important;
  transform: translate(18px, -13px);
  transition: all 0.23s ease 0.1s;
  position: relative;
  color: #5e5873 !important;
  margin: -0.4rem -0.7rem -0.4rem auto;
}

.modal .modal-footer {
  padding: 0.8rem 1.4rem;
}

.modal-footer,
.modal-footer {
  border-bottom-right-radius: calc(0.358rem - 1px) !important;
  border-bottom-left-radius: calc(0.358rem - 1px) !important;
  border-top: 1px solid rgba(34, 41, 47, 0.05) !important;
}

.modal-90 {
  min-width: 90%;
}

.text-green {
  color: var(--color-success);
}

.text-red {
  color: var(--color-danger);
}

.error-select {
  border: 1px solid var(--color-danger) !important;
  border-top: 3px solid var(--color-danger) !important;
  border-radius: 5px !important;
}

.td-tooltip {
  position: relative;
  display: inline-block;
}

.td-tooltiptext {
  visibility: hidden;
  background-color: #17181c;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
}

.td-tooltip:hover ~ .td-tooltiptext {
  visibility: visible;
}

.mx--15 {
  margin: 0 -15px;
}

.card-footer {
  background-color: var(--body-bg) !important;
  display: flex;
  justify-content: flex-end !important;
  border-top: 1px solid rgba(0,0,0,0.06) !important;
}

.card-header {
  padding: 1.5rem;
  margin-bottom: 0;
  background-color: transparent !important;
  border-bottom: 0 solid rgba(34, 41, 47, 0.125) !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 0.75rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 0.75rem !important;
}

.text-grey {
  color: #535051;
}

.table > :not(:first-child) {
  border-top: none !important;
}

button.fc--button.fc-button.fc-button-primary {
  display: none !important;
}
button.fc-next-button.fc-button.fc-button-primary {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.fc-toolbar-chunk {
  display: flex;
  align-items: center;
}

tr.fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid
  td {
  padding: 0 !important;
}

.error .react-select__control {
  border-color: var(--color-danger) !important;
}

.dropdown-toggle::after {
  display: inline-block !important;
  border-style: solid !important;
  border-color: #adadad !important;
  border-width: 0 2px 2px 0 !important;
  padding: 2.5px !important;
  vertical-align: middle !important;
  transition: transform 0.3s !important;
  transform: rotate(45deg) !important;
}

.sidebar-header-main {
  margin-left: 15px;
  max-height: 50px;
  overflow: hidden;
  margin-top: 10px;

  a {
    filter: drop-shadow(0px 0px 3px rgba(var(--theme-color-rgb), 0.05));
  }
}

input[type='file'] {
  background: rgba(var(--dark-blue-rgb), 0.12);
  width: 100%;
  color: var(--dark-blue) !important;
  border-radius: var(--border-radius) !important;
  cursor: pointer;
}
input[type='range'] {
  width: 100%;
}
::-webkit-file-upload-button {
  background: var(--dark-blue) !important;
  border: none;
  font-weight: bold;
  color: #fff !important;
  height: 37.8px;
  padding: 0.606rem 0.75rem;
  cursor: pointer;
}
::-ms-browse {
  background: var(--dark-blue);
  color: #fff;
  border: none;
  font-weight: bold;
  padding: 0.375rem 0.75rem;
  cursor: pointer;
}

.stop {
  will-change: unset !important;
}

.editor-wrapper {
  box-sizing: content-box;

  &:hover {
    .ql-container:not(:focus) {
      border-color: rgba(var(--theme-color-rgb), 0.15) !important;
    }
  }

  .ql-toolbar {
    border-top-left-radius: 0.357rem;
    border-top-right-radius: 0.357rem;
    margin-bottom: 0;
    padding: 0.5rem;
    border-color: rgba(var(--theme-color-rgb), 0.1) !important;
    border-bottom: 0px !important;
    visibility: visible !important;
  }
  .ql-editor {
    height: 100%;
    min-height: 10rem;
  }
  .ql-container {
    border-bottom-left-radius: 0.357rem;
    border-bottom-right-radius: 0.357rem;
    border-top: none !important;
    border: 1px solid rgba(var(--theme-color-rgb), 0.1) !important;
    min-height: 10rem;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    :focus {
      border: 1px solid var(--dark-blue) !important;
    }
  }
}

.btn:focus {
  box-shadow: none !important;
}

.form-control {
  &:focus {
    &::placeholder {
      transform: translate(5px);
      transition: all 0.2s ease;
    }
  }
}

.dropdown-menu {
  background-color: var(--body-bg) !important;
  border: 1.5px solid rgba(var(--theme-color-rgb), 0.05) !important;
  box-shadow: 0 5px 25px rgba(var(--theme-color-rgb), 0.01) !important;
}

.rwd-table tbody tr td {
  border-color: #e0e0e0;
  vertical-align: middle;
}

.rwd-table tfoot {
  th,
  td {
    border-color: #e0e0e0 !important;
  }
}

.bl-1 {
  border-left: 0.5px solid rgba(var(--theme-color-rgb), 0.1);
}
.bl-2 {
  border-left: 1px solid rgba(var(--theme-color-rgb), 0.1);
}
.br-1 {
  border-right: 0.5px solid rgba(var(--theme-color-rgb), 0.1);
}
.br-2 {
  border-right: 1px solid rgba(var(--theme-color-rgb), 0.1);
}
.bb-1 {
  border-bottom: 0.5px solid rgba(var(--theme-color-rgb), 0.1);
}
.bb-2 {
  border-bottom: 1px solid rgba(var(--theme-color-rgb), 0.1);
}

.list-group-item {
  background-color: rgba(var(--theme-color-rgb), 0.03) !important;
  color: var(--body-color) !important;
}

.ql-snow .ql-picker,
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label:not(:hover) {
  color: var(--body-color) !important;
}

.ql-snow .ql-picker-options {
  background-color: var(--body-bg) !important;
}

.ql-snow svg:not(:hover) .ql-stroke {
  color: var(--body-color);
  stroke: currentColor !important;
}
.ql-snow svg:not(:hover) .ql-fill {
  color: var(--body-color);
  stroke: currentColor !important;
}

.btn-muted {
  color: rgba(var(--theme-color-rgb), 0.6) !important;
}

.flatpickr-calendar {
  background: var(--body-bg) !important;
}

.object-fit {
  object-fit: contain;
}
.object-cover {
  object-fit: cover;
}

[class*='btn-light'] {
  font-weight: 500 !important;
}

.btn-link:not(.nav-user) {
  color: var(--color-primary) !important;
}

code {
  background: #d6338421;
  border-radius: var(--border-radius);
  padding: 2px 6px;
}

.swal2-popup {
  background: var(--body-bg) !important;
}

.no-padding {
  padding: 0px !important;
}

input[type='radio'] {
  border: 1px solid rgba(var(--theme-color-rgb), 0.1);
  background-color: var(--body-bg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  margin-left: -1rem;
  border-radius: 50%;
  min-width: 1.285rem;
  min-height: 1.285rem;
  max-width: 1.285rem;
  max-height: 1.285rem;
  vertical-align: top;
  -webkit-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  transition: 0.1s;

  &:checked {
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
    box-shadow: 0 2px 4px 0 rgb(var(--dark-blue-rgb), 0.4);
  }
}

.image-preview-group {
  width: 100%;
  margin-bottom: 0.3rem;
  display: flex;
  justify-content: center;

  div:not(.upload-options) {
    width: 100%;
    justify-content: center;
    display: flex;
    position: relative;
  }

  img {
    height: 250px;
  }
  img.radius {
    border-radius: 100%;
  }
}

.upload-options {
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  bottom: 0;
  // background-color: var(--body-bg);
  border-radius: var(--border-radius);

  button {
    border: none !important;
    width: 100%;
    padding: 0.5857rem 0;
    margin-bottom: 0 !important;
    font-weight: 600;
    cursor: pointer;
    background-color: rgba(var(--color-danger-rgb), 0.32);
    color: #fff !important;
    backdrop-filter: saturate(180%) blur(5px);
    font-size: 1rem !important;
  }
  label {
    border: none !important;
    width: 100%;
    padding: 0.5857rem 0;
    margin-bottom: 0 !important;
    font-weight: 600;
    cursor: pointer;
    font-size: 1rem !important;
    backdrop-filter: saturate(180%) blur(5px);
    background-color: rgba(var(--color-primary-rgb), 0.32);
    color: #fff !important;
  }

  & input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}

.image-preview {
  position: relative;
  overflow: hidden;
  &.preview {
    background-color: #ededed;
  }
}

.img-login {
  width: 100%;
  height: 100%;
  max-width: 35vw;
}

.nav-tabs {
  overflow-x: auto !important;
  overflow-y: hidden !important;
  display: flex;
  flex-wrap: nowrap !important;
  white-space: nowrap !important;
  width: 100%;
}

.hr-vertical {
  height: 23px;
  margin-top: 5px;
  width: 2px;
  background-color: rgba(var(--theme-color-rgb), 0.3);
}
.hr {
  height: 1px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: rgba(var(--theme-color-rgb), 0.1);
}

.min-h-250 {
  min-height: 250px;
}

.card-product {
  display: grid;
  padding: 10px 10px 0px;
}
.align-self-end {
  align-self: flex-end;
}

.d-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.custom.input-group {
  flex-wrap: nowrap !important;

  .input-group-text {
    padding: 0 !important;
    min-width: 30%;
    border-width: 0 1px 0 0 !important;
    border-color: rgba(var(--theme-color-rgb), 0.1) !important;
    background-color: transparent;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;

    background: transparent;
    border: none;
    padding: 0.375rem 1.35rem 0.375rem 0.75rem;
    width: 100%;

    text-overflow: ellipsis;
    overflow: hidden;

    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23d8d6de' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
    background-size: 22px 25px, 22px 25px;
    background-repeat: no-repeat;
    background-position-x: 99%;
    background-position-y: 4px;
  }

  div {
    padding: 0.375rem 0.75rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  input {
    border: none;
    padding: 0.375rem 0.75rem;
    width: 100%;
    background: transparent;
  }
}

.overflow-visible {
  overflow: visible;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.width-min {
  width: min-content;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}

.line-through {
  text-decoration: line-through;
}

.radius-4 {
  border-radius: 4px;
}

.shadow-low {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
}

.dot-left {
  display: flex;
  font-weight: bold;

  p {
    margin: 1rem 0 1rem 1rem;
  }

  &.active {
    color: var(--color-primary);
    &::before {
      background: var(--color-primary);
    }
  }
  &.active {
    color: var(--color-primary);
  }
  &::before {
    content: '';
    display: flex;
    width: 3px;
    background: #ccc;
  }
}

.touchable {
  border: 0px;
  outline: none !important;
  width: 100%;
  padding: 0px;
  margin: 0px;
  background-color: transparent;
}

.btn-img-placeholder {
  width: 100%;
  height: 300px;
  background-color: rgba(var(--theme-color-rgb), 0.05);
  display: flex;
  color: rgba(var(--theme-color-rgb), 0.8);
  justify-content: center;
  align-items: center;
}

.decoration-none {
  text-decoration: none !important;
}

.no-radius {
  border-radius: 0px !important;
}

.txt-dots {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.bouncing-loader {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}

.bouncing-loader > div {
  width: 12px;
  height: 12px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: rgba(var(--theme-color-rgb), 1);
  opacity: 0.2;
  animation: bouncing-loader 0.6s infinite alternate;
}

.bg-theme-light {
  background-color: rgba(var(--theme-color-rgb), 0.03);
}

.bordered-danger {
  border: 1px dashed rgba(var(--color-danger-rgb), 0.3);
  border-radius: 6px;
}
.bordered-success {
  border: 1px dashed rgba(var(--color-success-rgb), 0.3);
  border-radius: 6px;
}
.bordered-primary {
  border: 1px dashed rgba(var(--color-primary-rgb), 0.3);
  border-radius: 6px;
}

.input-group.custom.input-height input {
  height: 2.714rem;
}

.btn-none {
  outline: none;
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.border-1 {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.z-index-1 {
  z-index: 1;
}
.chat-container {
  min-height: 50vh;
}
.rce-mlist {
  height: 500px !important;
}
.rce-input-textarea {
  max-height: 41px;
  min-height: 41px;
  color: var(--body-color);
  background: transparent;
  z-index: 1;
}
.rce-navbar,
.rce-container-mlist,
.rce-container-input {
  z-index: 1;
}
.rce-container-input {
  background-color: rgba(var(--color-primary-rgb), 0.12) !important;
}
.color-white {
  color: #fff;
}
.border-none {
  border: none !important;
}
.border-default {
  border: 1px solid rgba(var(--theme-color-rgb), 0.18);
  border-radius: var(--border-radius);
}
.rce-citem {
  background: rgba(var(--theme-color-rgb), 0.02) !important;
}
.rce-mbox-time {
  color: #aaaaaa;
}
.rce-mbox-text {
  color: #000;
}
.chat-container-list {
  background-color: transparent !important;
}
.rce-citem-body {
  border-bottom: none !important;
}
.rce-citem-body--top-title {
  color: rgba(var(--theme-color-rgb), 0.9);
}
.rce-navbar.dark {
  background-color: var(--color-primary);
}
.rce-button {
  background-color: rgb(0, 0, 0) !important;
  color: rgb(255, 255, 255) !important;
}
.card-chat {
  background-color: var(--body-bg) !important;
  border-radius: 5px;
}
.rce-mbox-title {
  color: var(--color-primary);
}
.rce-citem-body--top-time {
  color: var(--txt-muted) !important;
}
.rce-citem-body--bottom-title {
  color: var(--body-color);
}
.rce-citem-avatar {
  display: none !important;
}
.rce-citem {
  padding: 10px;
  margin: 0;
  border-bottom: 1px solid rgba(var(--theme-color-rgb), 0.12) !important;
}
.chat-container-list.border-default {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.rce-citem:hover {
  background: rgba(var(--theme-color-rgb), 0.04) !important;
}

.upper {
  text-transform: uppercase;
}

.swal2-file {
  width: 75% !important;
}

.p-small {
  line-height: initial;
  font-size: 0.875em;
}

#printDeclaracao {
  font-weight: 500;
  scale: 0.5;
  margin: -15% 0 -15% -30%;
}

.termos {
  font-size: x-small;
}

.view-print {
  * {
    color: #000;
  }

  tbody tr td {
    font-weight: 500;
  }
}

.print-envio {
  .img-envio {
    width: 50px !important;
  }
  .infos-envio {
    width: 50% !important;
    h6 {
      font-size: 13px;
    }
  }
}

.table-responsive:has(> .min-height) {
  min-height: 200px;
}

.no-wrap {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;              /* "overflow" value must be different from "visible" */
  text-overflow:    ellipsis;
}

.scale-hover {
  transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);

  // filter: blur(1px) saturate(180%);
  &:hover {
    transform: scale(1.05);
  }
}

.color-black {
  color: #000;
}

.m-sub-header,
.m-header {
  line-height: 1.08349;
  font-weight: 600;
  letter-spacing: -0.003em;
  display: inline;
}
.m-sub-header {
  font-size: 25px !important;
}

.p-15px {
  padding: 15px;
}

.horizontal-list {
  overflow-y: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  .list-item:last-of-type {
    margin-right: 10px;
  }

  .list-item {
    overflow: hidden;
    margin-left: 10px;
    border-radius: 10px;
    transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
    .position-relative:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
      background: #000;
      opacity: 0.5;
    }
    &:hover {
      .position-relative:before {
        opacity: 0.1;
      }
      transform: translateY(-6px);
    }
  }
}

.position-relative {
  position: relative;
  width: 100%;
  height: 100%;
}
.list-item h3.position-absolute {
 z-index: 2;
 margin: 20px;
 color: #000 !important;
 font-weight: 600;
 text-shadow: 2px 0 #ffffff40, -2px 0 #ffffff40, 0 2px #ffffff40, 0 -2px #ffffff40,
             1px 1px #ffffff40, -1px -1px #ffffff40, 1px -1px #ffffff40, -1px 1px #ffffff40;
//  text-shadow: 2px 0 #00000040, -2px 0 #00000040, 0 2px #00000040, 0 -2px #00000040,
//              1px 1px #00000040, -1px -1px #00000040, 1px -1px #00000040, -1px 1px #00000040;
}
.position-absolute {
  position: absolute;
}


.btn-none {
  outline: none;
  padding: 0px;
  border: none;
}


.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid rgba(var(--theme-color-rgb), 0.125);
}

.separator:not(:empty)::before {
  margin-right: 1em;
}

.separator:not(:empty)::after {
  margin-left: 1em;
}

.color-dark-blue  {
  color: var(--dark-blue);
}

.bg--dark-blue {
  background-color: var(--dark-blue) !important;
}
.weight-600 {
  font-weight: 600 !important;
}


.color-primary-dark {
  color: #494433 !important;
}


[class*='chart-card'] {
  border-radius: 0.25rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  background-color: #fff;
}
